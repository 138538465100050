import React, { useContext, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { fetchCarouselImages, fetchCategories } from "../../functions/functions.ts";
import AddCarouselImage from "./tabs/AddCarouselImage";
import CarouselImagesList from "./tabs/CarouselImagesList.js";

function Home({setIsLoading}) {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchCarouselImages(setData);
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="w-80 m-auto">
              <ToastContainer />
              <div className="row my-3">
                <div className="">
                  <div className="card">
                    <div className="card-header p-2">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            href="#carousel_config"
                            data-toggle="tab"
                          >
                            Carousel Images Config
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="#carousel_img_create"
                            data-toggle="tab"
                          >
                            Add Carousel Image
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="card-body">
                      <div className="tab-content">
                        <div className="tab-pane active" id="carousel_config">
                          <CarouselImagesList data={data} setData={setData} setIsLoading={setIsLoading}/>
                        </div>

                        <div className="tab-pane " id="carousel_img_create">
                          <AddCarouselImage setData={setData} setIsLoading={setIsLoading}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
