import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import WallpaperState from "./context/WallpapersState";
import Product from "./components/Products/Product";
import UserMaster from "./components/UserMaster/UserMaster";
import LoginPage from "./components/Login/login";
import Category from "./components/category/Category";
import Offer from "./components/Offers/Offers";
import Brand from "./components/Brand/Brand";
import Home from "./components/HomeConfig/Home";
import Header from "./components/partial/Header";
import SideBar from "./components/partial/SideBar";

function App() {
  // const [isRefreshed, setIsRefreshed] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isAuthenticated") === "true"
  );
  const [admin, setAdmin] = useState(localStorage.getItem("role") === "admin");

  const [isLoading, setIsLoading] = useState(false);

  return (
    <WallpaperState>
      {isLoading ? (
        <div class="loader-div">
          <span class="loader">
            <span></span>
            <span></span>
          </span>
        </div>
      ) : (
        ""
      )}
      <BrowserRouter>
        {isAuthenticated && (
          <>
            <Header />
            <SideBar />
          </>
        )}
        <Routes>
          {/* Always show LoginPage when not authenticated */}
          {!isAuthenticated && <Route path="*" element={<LoginPage />} />}

          {isAuthenticated && (
            <>
              {/* header and sidebar */}
              {/* content */}
              {/* <Route path="/" element={<Dashboard />} /> */}
              <Route path="/" element={<Product setIsLoading={setIsLoading}/>} />
              <Route path="/brands" element={<Brand setIsLoading={setIsLoading}/>} />
              {admin ? <Route path="/users" element={<UserMaster setIsLoading={setIsLoading}/>} /> : ""}
              <Route path="/carousel-images-config" element={<Home setIsLoading={setIsLoading}/>} />
              <Route path="/categories" element={<Category setIsLoading={setIsLoading}/>} />
              <Route path="/offers" element={<Offer setIsLoading={setIsLoading}/>} />
              {/* End content */}
            </>
          )}
        </Routes>
      </BrowserRouter>
    </WallpaperState>
  );
}

export default App;
