import React, { useState, useEffect, useCallback, useMemo } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import DataTable from "react-data-table-component";
import {
  handleCategoryDelete,
  base,
  fetchHomeConfig,
  handleCarouselImageUpdate,
  updateCarouselImage,
} from "../../../functions/functions.ts";

function CarouselImagesList({ data, setData, setIsLoading }) {
  const [homeConfig, setHomeConfig] = useState({
    carouselImages: [],
    displayOffers: [],
    displayBrands: [],
    displayProducts: [],
    __v: 0,
  });

  useEffect(() => {
    setIsLoading(true);

    // Fetch home config
    fetchHomeConfig(setHomeConfig)
      .then(() => {
        // Home config fetched successfully, hide loader
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle error
        console.error("Error fetching home config:", error);
        setIsLoading(false);
        // You might want to show an error message to the user
      });
  }, []);

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [backLink, setBackLink] = useState("");
  const [isPhone, setIsPhone] = useState(false);

  const onDropMainFile = useCallback((acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
  }, []);

  const acceptMainFileExtensions = useMemo(() => {
    const accept = {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    };
    return accept;
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropMainFile,
    maxFiles: 1,
    accept: acceptMainFileExtensions,
  });

  const [editedImageId, setEditedImageId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleEdit = (image) => {
    setEditedImageId(image._id);
    setIsLoading(Boolean(image.isPhone))
    setBackLink(image.backLink);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditedImageId(null);
  };

  const handleSaveChanges = () => {
    setShowModal(false);
    setIsLoading(true);

    // Update carousel image
    updateCarouselImage(editedImageId, isPhone, backLink, file)
      .then(() => {
        // Image updated successfully, hide loader and close modal
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating carousel image:", error);
        setIsLoading(false);
        // You might want to show an error message to the user
      });
  };

  const handleCheckboxChange = (e) => {
    setIsPhone(e.target.checked);
  };

  const columns = [
    {
      name: "Home Display",
      cell: (row) => (
        <input
          className="ml-4"
          type="checkbox"
          checked={homeConfig.carouselImages.includes(row._id)}
          onChange={() => {
            handleCarouselImageUpdate(row._id, setHomeConfig);
          }}
        />
      ),
    },
    {
      name: "Created At",
      selector: (row) => {
        var today = new Date(row.createdAt).toLocaleDateString();
        return today;
      },
    },
    {
      name: "Back Link",
      cell: (row) => <div>{row.backLink}</div>,
    },
    {
      name: "Phone Image",
      cell: (row) => <div>{row.isPhone ? "Yes" : "No"}</div>,
    },
    {
      name: "Image",
      cell: (row) => <img src={base + row.url} alt={"Image"} width={100} />,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="btn-group">
          <button
            className="btn mx-2 btn-primary btn-sm"
            onClick={() => handleEdit(row)}
          >
            Edit
          </button>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => {
              handleCategoryDelete(row._id, setData);
            }}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <DataTable
          theme="dark"
          columns={columns}
          data={data}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="60vh"
          highlightOnHover
        />
      </div>

      {/* Bootstrap Modal for Editing Image */}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
        id="editImageModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editImageModalLabel"
        aria-hidden={!showModal}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="editImageModalLabel"
                style={{ color: "black" }}
              >
                Edit Image
              </h5>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="backLinkInput" style={{ color: "black" }}>
                  Back Link:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="backLinkInput"
                  value={backLink}
                  onChange={(e) => setBackLink(e.target.value)}
                />
              </div>
              <div className="form-check mb-3">
            <input
              type="checkbox"
              onChange={handleCheckboxChange}
              className={`form-check-input`}
              // style={{
              //   background: "none",
              //   border: "1px solid rgba(131, 131,131,0.2)",
              //   color: "white",
              // }}
            />
            <label className="form-check-label" style={{color: "black"}}>- Display on phone</label>
          </div>
              <Dropzone>
                {() => (
                  <div
                    {...getRootProps()}
                    style={{
                      border: "1px solid rgba(131,131,131,0.2)",
                      borderRadius: "5px",
                      height: "100px",
                      cursor: "pointer",
                      margin: "10px 0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <input {...getInputProps()} />

                    {fileName ? (
                      <p style={{ color: "black" }}>{fileName}</p>
                    ) : (
                      <p style={{ color: "black" }}>
                        <i className="fa fa-picture-o" aria-hidden="true"></i>
                        <br />
                        Upload an (File Format .png, .jpg) file
                      </p>
                    )}
                  </div>
                )}
              </Dropzone>
              {/* Input fields for editing image */}
              {/* Replace with your image editing fields */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={handleCloseModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSaveChanges}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
      ></div>
    </>
  );
}

export default CarouselImagesList;
