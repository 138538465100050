import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SliderPicker } from "react-color";
import Dropzone, { useDropzone } from "react-dropzone";
import { MultiSelect } from "react-multi-select-component";
import axiosInstance, { createProduct } from "../../functions/functions";

function ProductCreate({ setData, setIsLoading }) {
  const [categoryArray, setCategoryArray] = useState([]);
  const [brandsArray, setBrandsArray] = useState([]);
  const [mainFile, setMainFile] = useState(null);
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [description, setDescripton] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [backLink, setBackLink] = useState("");
  const [overlayText, setOverlayText] = useState("");
  const [title, setTitle] = useState("");
  const [mainFileName, setMainFileName] = useState("");

  const populateCategory = async () => {
    const response = await axiosInstance.post(`/api/category/list`);
    const formattedData = response.data.category.map((category) => ({
      title: category.title,
      _id: category._id,
    }));
    setCategoryArray(formattedData);
  };
  const populateBrands = async () => {
    const response = await axiosInstance.post(`/api/brand/list`);
    const formattedData = response.data.brand.map((brand) => ({
      title: brand.title,
      _id: brand._id,
    }));
    setBrandsArray(formattedData);
  };

  const onDropMainFile = useCallback((acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setMainFile(selectedFile);
    setMainFileName(selectedFile.name);
    // console.log(selectedFile.name);
  }, []);
  const handleUpload = useCallback(async () => {
    setIsLoading(true);
    try {
      await createProduct({
        title,
        category,
        description,
        overlayText,
        couponCode,
        backLink,
        image: mainFile,
        brand,
        setData,
      });
    } catch (error) {
      // Handle error
      console.error("Error creating product:", error);
      // You might want to show an error message to the user
    }
    setIsLoading(false);
  }, [category, brand, mainFile, description]);

  const acceptMainFileExtensions = useMemo(() => {
    const accept = {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
    };
    return accept;
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropMainFile,
    maxFiles: 1,
    accept: acceptMainFileExtensions,
  });

  useEffect(() => {
    populateCategory();
    populateBrands();
  }, []);

  return (
    <>
      <div className="w-80 m-auto">
        <div
          style={{ backgroundColor: "#1818184a !important" }}
          className="modal-header"
        >
          <h5
            style={{ width: "95%" }}
            className="modal-title d-flex justify-content-between"
            id="exampleModalLabel"
          >
            Add Product
          </h5>
        </div>
        <div className="modal-body">
          <div className="container">
            <div className="col d-flex mt-3 flex-column">
              <label>Title</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                placeholder="Enter product title"
                style={{
                  padding: "0.4rem 0.3rem",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  borderRadius: "4px",
                  width: "100%",
                  color: "rgb(131,131,131)",
                }}
              />
            </div>
            <div className="col d-flex mt-3 flex-column">
              <label>Category</label>
              <select
                style={{
                  padding: "0.4rem 0.3rem",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  borderRadius: "4px",
                  width: "100%",
                  color: "rgb(131,131,131)",
                }}
                id="categorySelect"
                className="mySelect"
                defaultValue={""}
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value={""}>Select category</option>
                {categoryArray.map((category) => (
                  <option key={category._id} value={category.title}>
                    {category.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="col d-flex mt-3 flex-column">
              <label>Description</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setDescripton(e.target.value);
                }}
                placeholder="Enter product description"
                style={{
                  padding: "0.4rem 0.3rem",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  borderRadius: "4px",
                  width: "100%",
                  color: "rgb(131,131,131)",
                }}
              />
            </div>
            <div className="col d-flex mt-3 flex-column">
              <label>Coupon Code</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setCouponCode(e.target.value);
                }}
                placeholder="Enter coupon code"
                style={{
                  padding: "0.4rem 0.3rem",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  borderRadius: "4px",
                  width: "100%",
                  color: "rgb(131,131,131)",
                }}
              />
            </div>
            <div className="col d-flex mt-3 flex-column">
              <label>Back Link</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setBackLink(e.target.value);
                }}
                placeholder="Enter back link"
                style={{
                  padding: "0.4rem 0.3rem",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  borderRadius: "4px",
                  width: "100%",
                  color: "rgb(131,131,131)",
                }}
              />
            </div>
            <div className="col d-flex mt-3 flex-column">
              <label>Overlay Text</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setOverlayText(e.target.value);
                }}
                placeholder="Enter overlay text"
                style={{
                  padding: "0.4rem 0.3rem",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  borderRadius: "4px",
                  width: "100%",
                  color: "rgb(131,131,131)",
                }}
              />
            </div>

            <div className="col d-flex mt-3 flex-column">
              <label>Brand</label>
              <select
                style={{
                  padding: "0.4rem 0.3rem",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  borderRadius: "4px",
                  width: "100%",
                  color: "rgb(131,131,131)",
                }}
                id="colorselect"
                className="mySelect"
                defaultValue={"none"}
                onChange={(e) => setBrand(e.target.value)}
              >
                <option key={"none"} value={""}>
                  Select Brand
                </option>
                {brandsArray.map((brand) => (
                  <option key={brand._id} value={brand.title}>
                    {brand.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="row mt-3 drop-border">
              <h3>Product Image</h3>
              <div>IDEAL IMAGE SIZE - 354x200</div>
              <Dropzone
                onDrop={onDropMainFile}
                maxFiles={1}
                accept={acceptMainFileExtensions}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      height: "100px",
                      cursor: "pointer",
                      margin: "10px 0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <input {...getInputProps()} />
                    {mainFileName ? (
                      <p>{mainFileName}</p>
                    ) : (
                      <p>
                        <i className="fa fa-picture-o" aria-hidden="true"></i>
                        <br />
                        Upload an (File Format .png, .jpg etc...) file
                      </p>
                    )}
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        </div>
        <div className="modal-footer justify-content-center">
          <button
            type="button"
            className="btn"
            style={{
              padding: "8px 16px",
              background:
                "linear-gradient(101.43deg, #FBD341 0%, #C59431 100%)",
              borderRadius: "12px",
              color: "white",
              fontWeight: "bold",
            }}
            onClick={handleUpload}
          >
            Upload
          </button>
        </div>
      </div>
    </>
  );
}

export default ProductCreate;
