import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SliderPicker } from "react-color";
import Dropzone, { useDropzone } from "react-dropzone";
import { MultiSelect } from "react-multi-select-component";
import axiosInstance, { createBrand } from "../../functions/functions";

function BrandCreate({ setData, setIsLoading }) {
  const [headerImg, setHeaderImg] = useState(null);
  const [description, setDescripton] = useState("");
  const [sideText, setSideText] = useState("");
  const [colorCode, setColorCode] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [backLink, setBackLink] = useState("");
  const [sideBySideImg, setSideBySideImg] = useState(null);
  const [products, setProducts] = useState([]);
  const [productsArray, setProductsArray] = useState([]);
  const [title, setTitle] = useState("");
  const [brandLogo, setBrandLogo] = useState(null);
  const [headerImgName, setHeaderImgName] = useState("");
  const [sideBySideImgName, setSideBySideImgName] = useState("");
  const [brandLogoName, setBrandLogoName] = useState("");

  const populateProducts = async () => {
    const response = await axiosInstance.post("/api/product/list");
    const formattedData = response.data.product.map((product) => ({
      title: product.title,
      _id: product._id,
    }));
    setProductsArray(formattedData);
  };

  const onDropHeaderImg = useCallback((acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setHeaderImg(selectedFile);
    setHeaderImgName(selectedFile.name);
    // console.log("Header Image File Name:", selectedFile.name);
  }, []);

  const onDropBrandLogo = useCallback((acceptedFiles) => {
    setBrandLogo(acceptedFiles[0]);
    const selectedFile = acceptedFiles[0];
    setBrandLogoName(selectedFile.name);
    // console.log("Brand Logo File Name:", selectedFile.name);
  }, []);


  const acceptBrandLogoExtensions = useMemo(() => {
    const accept = {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
    };
    return accept;
  }, []);

  const handleUpload = async () => {
    setIsLoading(true);
  
    try {
      await createBrand({
        title,
        headerImg,
        colorCode,
        description,
        sideImg: sideBySideImg,
        couponCode,
        sideText,
        brandLogo,
        backLink,
        // products,
        setData,
      });
    } catch (error) {
      // Handle error
      console.error("Error creating brand:", error);
      // You might want to show an error message to the user
    }
  
    setIsLoading(false);
  };
  

  const acceptHeaderImgExtensions = useMemo(() => {
    const accept = {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    };
    return accept;
  }, []);

  const onDropSideBySideImg = useCallback((acceptedFiles) => {
    setSideBySideImg(acceptedFiles[0]);
    const selectedFile = acceptedFiles[0];
    setSideBySideImgName(selectedFile.name);
    // console.log("Side Img File Name:", selectedFile.name);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropHeaderImg,
    maxFiles: 1,
    accept: acceptHeaderImgExtensions,
  });

  useEffect(() => {
    populateProducts();
  }, []);

  return (
    <>
      <div className="w-80 m-auto">
        <div
          style={{ backgroundColor: "#1818184a !important" }}
          className="modal-header"
        >
          <h5
            style={{ width: "95%" }}
            className="modal-title d-flex justify-content-between"
            id="exampleModalLabel"
          >
            Add Brand
          </h5>
        </div>
        <div className="modal-body">
          <div className="container">
            <div className="col d-flex flex-column">
              <label>Title</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                placeholder="Enter title"
                style={{
                  padding: "0.4rem 0.3rem",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  borderRadius: "4px",
                  width: "100%",
                  color: "rgb(131,131,131)",
                }}
              />
            </div>
            <div className="col d-flex mt-3 flex-column">
              <label>Background Color</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setColorCode(e.target.value);
                }}
                placeholder="Enter background color code ( with # )"
                style={{
                  padding: "0.4rem 0.3rem",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  borderRadius: "4px",
                  width: "100%",
                  color: "rgb(131,131,131)",
                }}
              />
            </div>
            <div className="col d-flex mt-3 flex-column">
              <label>Description</label>
              <textarea
                className="form-control"
                onChange={(e) => {
                  setDescripton(e.target.value);
                }}
                placeholder="Enter description"
                style={{
                  padding: "0.4rem 0.3rem",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  borderRadius: "4px",
                  width: "100%",
                  color: "rgb(131,131,131)",
                }}
              />
            </div>
            <div className="col d-flex mt-3 flex-column">
              <label>Side Text</label>
              <textarea
                className="form-control"
                onChange={(e) => {
                  setSideText(e.target.value);
                }}
                placeholder="Enter side text"
                style={{
                  padding: "0.4rem 0.3rem",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  borderRadius: "4px",
                  width: "100%",
                  color: "rgb(131,131,131)",
                }}
              />
            </div>
            <div className="col d-flex mt-3 flex-column">
              <label>Coupon Code</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setCouponCode(e.target.value);
                }}
                placeholder="Enter coupon code"
                style={{
                  padding: "0.4rem 0.3rem",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  borderRadius: "4px",
                  width: "100%",
                  color: "rgb(131,131,131)",
                }}
              />
            </div>
            <div className="col d-flex mt-3 flex-column">
              <label>Back Link</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setBackLink(e.target.value);
                }}
                placeholder="Enter backlink"
                style={{
                  padding: "0.4rem 0.3rem",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  borderRadius: "4px",
                  width: "100%",
                  color: "rgb(131,131,131)",
                }}
              />
            </div>
            {/* <div className="col d-flex mt-3 flex-column">
              <label>Products</label>
              <select
                multiple
                style={{
                  padding: "0.4rem 0.3rem",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  borderRadius: "4px",
                  width: "100%",
                  color: "white",
                }}
                className="mySelect"
                // defaultValue={[]}
                value={products}
                onChange={(e) => {
                  const selectedOptions = e.target.selectedOptions;
                  const selectedProducts = Array.from(selectedOptions).map(
                    (option) => option.value
                  );
                  setProducts(selectedProducts);
                }}
              >
                <option value={""}>Select products</option>
                {productsArray.map((product) => (
                  <option key={product._id} value={product._id}>
                    {product.title}
                  </option>
                ))}
              </select>
            </div> */}
            <div className="mt-3 row drop-border">
              <h3>Header Image</h3>
              <Dropzone
                onDrop={onDropHeaderImg}
                maxFiles={1}
                accept={acceptHeaderImgExtensions}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      height: "100px",
                      cursor: "pointer",
                      margin: "10px 0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <input {...getInputProps()} />
                    {headerImgName ? (
                      <p>{headerImgName}</p>
                    ) : (
                      <p>
                        <i className="fa fa-picture-o" aria-hidden="true"></i>
                        <br />
                        Upload a file (File Format .png, .jpg)
                      </p>
                    )}
                  </div>
                )}
              </Dropzone>
            </div>
            <div className="row drop-border">
              <h3>Brand Logo</h3>
              <Dropzone
                onDrop={onDropBrandLogo}
                maxFiles={1}
                accept={acceptHeaderImgExtensions}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      height: "100px",
                      cursor: "pointer",
                      margin: "10px 0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <input {...getInputProps()} />
                    {brandLogoName ? (
                      <p>{brandLogoName}</p>
                    ) : (
                      <p>
                        <i className="fa fa-picture-o" aria-hidden="true"></i>
                        <br />
                        Upload a file (File Format .png, .jpg)
                      </p>
                    )}
                  </div>
                )}
              </Dropzone>
            </div>
            <div className="row drop-border">
              <h3>Side Img File</h3>
              <Dropzone
                onDrop={onDropSideBySideImg}
                maxFiles={1}
                accept={acceptHeaderImgExtensions}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      height: "100px",
                      cursor: "pointer",
                      margin: "10px 0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <input {...getInputProps()} />
                    {sideBySideImgName ? (
                      <p>{sideBySideImgName}</p>
                    ) : (
                      <p>
                        <i className="fa fa-picture-o" aria-hidden="true"></i>
                        <br />
                        Upload a file (File Format .png, .jpg)
                      </p>
                    )}
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        </div>
        <div className="modal-footer justify-content-center">
          <button
            type="button"
            className="btn"
            style={{
              padding: "8px 16px",
              background:
                "linear-gradient(101.43deg, #FBD341 0%, #C59431 100%)",
              borderRadius: "12px",
              color: "white",
              fontWeight: "bold",
            }}
            onClick={handleUpload}
          >
            Upload
          </button>
        </div>
      </div>
    </>
  );
}

export default BrandCreate;
