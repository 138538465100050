import React, { useState, useMemo, useCallback } from "react";
import DataTable from "react-data-table-component";
import { base, handleCategoryDelete, updateCategory } from "../../../functions/functions.ts";
import Dropzone, { useDropzone } from "react-dropzone";

function CategoryList({ data, setData, setIsLoading }) {
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const [categoryName, setCategoryName] = useState("");

  
  const [file, setFile] = useState(null);
  
  const onDropMainFile = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
    // console.log(acceptedFiles[0]);
  }, []);

  const acceptMainFileExtensions = useMemo(() => {
    const accept = {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
    };
    return accept;
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropMainFile,
    maxFiles: 1,
    accept: acceptMainFileExtensions,
  });

  const columns = [
    {
      name: "Name",
      selector: (row) => row.title,
    },
    {
      name: "Created At",
      selector: (row, index) => {
        var today = new Date(row.createdAt).toLocaleDateString();
        return today;
      },
    },
    {
      name: "Image",
      cell: (row) => (
        <img
          src={base + row.icon}
          alt={"Image"}
          width={100}
        />
      ),
    },
    {
      name: "Action",
      selector: (row, index) => {
        return (
          <div className="btn-group">
            <button
              className="btn mx-2 btn-primary btn-sm"
              onClick={() => handleEditCategory(row)}
            >
              Edit
            </button>
            <button
              className="btn btn-danger btn-sm"
              onClick={() => {
                handleCategoryDelete(row._id, setData);
              }}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  const handleEditCategory = (category) => {
    setCategoryId(category._id);
    setCategoryName(category.title);
    setShowCategoryModal(true);
  };

  const handleCloseCategoryModal = () => {
    setShowCategoryModal(false);
  };

  const handleCategoryNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleSaveCategoryChanges = () => {
    setShowCategoryModal(false);
    setIsLoading(true);
  
    // Update category
    updateCategory(categoryId, categoryName, file)
    .then(() => {
      // Category updated successfully, hide loader and close modal
      
      setIsLoading(false);
    })
    .catch(error => {
      // Handle error
      console.error("Error updating category:", error);
      setIsLoading(false);
      // You might want to show an error message to the user
    });
  };
  
  return (
    <>
      <div>
        <DataTable
          theme="dark"
          columns={columns}
          data={data}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="60vh"
          highlightOnHover
        />
      </div>

      <div
        className={`modal fade ${showCategoryModal ? "show" : ""}`}
        style={{ display: showCategoryModal ? "block" : "none" }}
        id="editCategoryModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editCategoryModalLabel"
        aria-hidden={!showCategoryModal}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" style={{color: "black"}}>Edit Category</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseCategoryModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="categoryNameInput" style={{color: "black"}}>Category Name:</label>
                <input
                  type="text"
                  className="form-control"
                  id="categoryNameInput"
                  value={categoryName}
                  onChange={handleCategoryNameChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="imageInput" style={{ color: "black" }}>
                  Image:
                </label>
                <Dropzone
                  onDrop={onDropMainFile}
                  accept={Object.keys(acceptMainFileExtensions)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      style={{
                        border: "1px solid rgba(131,131,131,0.2)",
                        borderRadius: "5px",
                        height: "100px",
                        cursor: "pointer",
                        margin: "10px 0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <input {...getInputProps()} />
                      {file ? (
                        <p style={{ color: "black" }}>
                          <i className="fa fa-file" aria-hidden="true"></i>
                          <br />
                          {file.name}
                        </p>
                      ) : (
                        <p style={{ color: "black" }}>
                          <i className="fa fa-picture-o" aria-hidden="true"></i>
                          <br />
                          Upload a file (File Format .png, .jpg)
                        </p>
                      )}
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCloseCategoryModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSaveCategoryChanges}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoryList;
