import React from "react";
import axios from "axios";
import { errorPopup, successPopup, infoPopup } from "./popupMessages";

// export const base = `http://localhost:4000/`;
// const axiosInstance = axios.create({
//   baseURL: base,
//   headers: {
//     "Access-Control-Allow-Origin": "http://localhost:3000",
//     Authorization: `Bearer debugflow@#$$`,
//   },
// });

export const base = `https://api.uklickme.com/`;
const axiosInstance = axios.create({
  baseURL: base,
  headers: {
    "Access-Control-Allow-Origin": "https://admin.uklickme.com/",
    Authorization: `Bearer debugflow@#$$`,
  },
});

export default axiosInstance;

export async function authenticateUser(email: string, password: string) {
  try {
    const response = await axiosInstance.post(`/api/auth/login`, {
      email,
      password,
    });
    if (response.data.success) {
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("role", response.data.payLoadData.role);
      successPopup("Login Successfull");
      // if (response.data.role.toLowerCase() !== "admin") {
      //   localStorage.setItem("email", email)
      // }
      // localStorage.setItem("role", response.data.role.toLowerCase())
      window.location.reload();
    }
  } catch (error) {
    errorPopup("Username or password is incorrect.");
  }
}


export async function createUser(
  setUsers: React.Dispatch<React.SetStateAction<any[]>>,
  username: string,
  email: string,
  password: string,
) {
  try {
    await axiosInstance.post(`/api/user/create`, {
      user: username,
      email,
      password,
    });
    getUserData(setUsers, "");
    successPopup("User created successfully");
  } catch (error) {
    errorPopup("Error creating user");
  }
}
export async function fetchCategories(
  setCategories: React.Dispatch<React.SetStateAction<any[]>>
) {
  try {
    const response = await axiosInstance.post(`/api/category/list`);
    setCategories(response.data.category);
  } catch (error) {}
}
export async function fetchHomeConfig(
  setHomeConfig: React.Dispatch<React.SetStateAction<any[]>>
) {
  try {
    const response = await axiosInstance.get(`/api/home/getAllArrays`);
    setHomeConfig(response.data.homeConfig);
  } catch (error) {}
}
export async function fetchBrands(
  setBrands: React.Dispatch<React.SetStateAction<any[]>>
) {
  try {
    const response = await axiosInstance.post(`/api/brand/list`);
    setBrands(response.data.brand);
  } catch (error) {}
}

export async function handleCategoryDelete(
  id: string,
  setCategories: React.Dispatch<React.SetStateAction<any[]>>
) {
  try {
    // Display confirmation dialog
    const isConfirmed = window.confirm("Do you want to delete this category?");
    if (isConfirmed) {
      await axiosInstance.delete(`/api/category/delete/${id}`);
      fetchCategories(setCategories);
      successPopup(`Category deleted successfully`);
    }
  } catch (error) {
    errorPopup(`There was a problem in deleting category`);
  }
}

export async function createCategory(
  name: string,
  file: File,
  setCategories: React.Dispatch<React.SetStateAction<any[]>>
): Promise<any> {
  try {
    // Check if name already exists
    const response = await axiosInstance.post(`/api/category/list`);
    const existingCategory = response.data.category.find(
      (category: any) => category.title === name
    );
    if (existingCategory) {
      infoPopup(`Category with name ${name} already exists`);
    }
    // Create new category
    const formData = new FormData();
    formData.append("title", name);
    formData.append("icon", file);
    try {
      await axiosInstance({
        method: "post",
        url: `/api/category/create`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      // console.log(response);
      successPopup(`Category created successfully`);
      fetchCategories(setCategories);
    } catch (error) {
      // console.log(error);
    }
  } catch (error) {
    // console.log(error);
    errorPopup(`Error creating category`);
  }
}

export async function fetchOffers(
  setOffers: React.Dispatch<React.SetStateAction<any[]>>
) {
  try {
    const response = await axiosInstance.post(`/api/offer/list`);
    // console.log(response.data.category)
    setOffers(response.data.offer);
  } catch (error) {}
}
export async function fetchCarouselImages(
  setImages: React.Dispatch<React.SetStateAction<any[]>>
) {
  try {
    const response = await axiosInstance.post(`/api/home/list`);
    // console.log(response.data.category)
    setImages(response.data.carouselImage);
  } catch (error) {}
}

export async function createOffer(
  name: string,
  file: File,
  setOffers: React.Dispatch<React.SetStateAction<any[]>>
): Promise<any> {
  try {
    // Check if name already exists
    const response = await axiosInstance.post(`/api/offer/list`);
    const existingOffer = response.data.offer.find(
      (offer: any) => offer.title === name
    );
    if (existingOffer) {
      infoPopup(`Offer with name ${name} already exists`);
    }
    // Create new category
    const formData = new FormData();
    formData.append("backLink", name);
    formData.append("image", file);
    try {
      await axiosInstance({
        method: "post",
        url: `/api/offer/create`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      // console.log(response);
      successPopup(`Offer created successfully`);
      fetchOffers(setOffers);
    } catch (error) {
      // console.log(error);
    }
  } catch (error) {
    // console.log(error);
    errorPopup(`Error creating offer`);
  }
}
export async function createCarouselImage(
  file: File,
  backLink: string,
  isPhone: boolean,
  setImages: React.Dispatch<React.SetStateAction<any[]>>
): Promise<any> {
  try {
    // Check if name already exists

    // Create new category
    const formData = new FormData();
    formData.append("image", file);
    formData.append("isPhone", String(isPhone));
    formData.append("backLink", backLink);

    try {
      await axiosInstance({
        method: "post",
        url: `/api/home/create`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      // console.log(response);
      successPopup(`Carousel Image added successfully`);
      fetchCarouselImages(setImages);
    } catch (error) {
      // console.log(error);
    }
  } catch (error) {
    // console.log(error);
    errorPopup(`Error creating offer`);
  }
}

export async function handleOfferDelete(
  id: string,
  setOffers: React.Dispatch<React.SetStateAction<any[]>>
) {
  try {
    // Display confirmation dialog
    const isConfirmed = window.confirm("Do you want to delete this offer?");
    if (isConfirmed) {
      await axiosInstance.delete(`/api/offer/delete/${id}`);
      fetchOffers(setOffers);
      successPopup(`Offer deleted successfully`);
    }
  } catch (error) {
    errorPopup(`There was a problem in deleting Offer`);
  }
}

export async function handleOfferDisplayUpdate(
  id: string,
  setHomeConfig: React.Dispatch<React.SetStateAction<any[]>>
) {
  try {
    // Assuming axiosInstance is properly configured
    await axiosInstance.post(`/api/home/update/displayOffers/${id}`);
    // Assuming the response contains updated homeConfig
    fetchHomeConfig(setHomeConfig);
  } catch (error) {
    // console.error("Error updating display offers:", error);
  }
}
export async function handleCarouselImageUpdate(
  id: string,
  setHomeConfig: React.Dispatch<React.SetStateAction<any[]>>
) {
  try {
    // Assuming axiosInstance is properly configured
    await axiosInstance.post(`/api/home/update/carouselImages/${id}`);
    // Assuming the response contains updated homeConfig
    fetchHomeConfig(setHomeConfig);
  } catch (error) {
    // console.error("Error updating display offers:", error);
  }
}
export async function handleBrandsDisplayUpdate(
  id: string,
  setHomeConfig: React.Dispatch<React.SetStateAction<any[]>>
) {
  try {
    await axiosInstance.post(`/api/home/update/displayBrands/${id}`);
    fetchHomeConfig(setHomeConfig);
  } catch (error) {}
}
export async function handleProductsDisplayUpdate(
  id: string,
  setHomeConfig: React.Dispatch<React.SetStateAction<any[]>>
) {
  try {
    await axiosInstance.post(`/api/home/update/displayProducts/${id}`);
    fetchHomeConfig(setHomeConfig);
  } catch (error) {}
}

export async function fetchProducts(
  setData: React.Dispatch<React.SetStateAction<any[]>>,
  page: string,
  limit: string
) {
  try {
    // Check if name already exists
    const response = await axiosInstance.post(`/api/product/list`, {
      page,
      limit,
    });
    setData(response.data.product);
  } catch (error) {}
}


export async function createBrand({
  title,
  headerImg,
  sideText,
  colorCode,
  sideImg,
  couponCode,
  brandLogo,
  description,
  backLink,
  // products,
  setData,
}: {
  title: string;
  headerImg: File;
  description: string;
  colorCode: string;
  sideImg: File;
  couponCode: string;
  sideText: string;
  brandLogo: File;
  backLink: string;
  // products: Array<object>;
  setData: React.Dispatch<React.SetStateAction<any[]>>;
}) {
  // send formdata and string data together
  const formData = new FormData();
  formData.append("title", title);
  formData.append("headerImg", headerImg);
  formData.append("colorCode", colorCode);
  formData.append("description", description);
  formData.append("couponCode", couponCode);
  formData.append("sideText", sideText);
  formData.append("sideImg", sideImg);
  formData.append("brandLogo", brandLogo);
  formData.append("backLink", backLink);

  // Convert the array of objects to a JSON string and append it
  // formData.append("products", JSON.stringify(products));

  try {
    await axiosInstance.post(`/api/brand/create`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    successPopup(`Brand created successfully`);
    fetchBrands(setData);
  } catch (error) {
    // Type assertion to inform TypeScript about the shape of the error object
    const errorMessage = (error as any).response?.data?.error || "Error creating brand.";
    errorPopup(errorMessage);
  }
}


export async function createProduct({
  title,
  category,
  description,
  overlayText,
  couponCode,
  backLink,
  image,
  brand,
  setData,
}: {
  title: string;
  category: string;
  description: string;
  image: File;
  overlayText: string;
  couponCode: string;
  backLink: string;
  brand: string;
  setData: React.Dispatch<React.SetStateAction<any[]>>;
}) {
  // send formdata and string data together
  const formData = new FormData();
  formData.append("image", image);
  formData.append("title", title);
  formData.append("description", description);
  formData.append("overlayText", overlayText);
  formData.append("category", category);
  formData.append("couponCode", couponCode);
  formData.append("backLink", backLink);
  formData.append("brand", brand);

  try {
    await axiosInstance.post(`/api/product/create`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
    fetchProducts(setData, "1", "10000");
    successPopup(`Product created successfully`);
  } catch (error) {
    // console.log(error);
    errorPopup("Error creating product.");
  }
}

export async function deleteProduct(
  id: string,
  page: string,
  limit: string,
  setProducts: React.Dispatch<React.SetStateAction<any[]>>
) {
  try {
    const isConfirmed = window.confirm("Are you sure you want to delete this product?");
    if (isConfirmed) {
      await axiosInstance.delete(`/api/product/delete/${id}`);
      fetchProducts(setProducts, page, limit);
      successPopup("Product Deleted Successfully");
    }
  } catch (error) {
    errorPopup("Failed to delete product");
  }
}

export async function deleteBrand(
  id: string,
  setBrands: React.Dispatch<React.SetStateAction<any[]>>
) {
  try {
    const isConfirmed = window.confirm("Are you sure you want to delete this brand?");
    if (isConfirmed) {
      await axiosInstance.delete(`/api/brand/delete/${id}`);
      fetchBrands(setBrands);
      successPopup("Brand Deleted Successfully");
    }
  } catch (error) {
    errorPopup("Failed to delete brand");
  }
}

export async function getUserData(
  setUsers: React.Dispatch<React.SetStateAction<any[]>>,
  filter: string
) {
  try {
    const response = await axiosInstance.post(`/api/admin/users/list`, {
      filter,
    });
    setUsers(response.data.users);
  } catch (error) {}
}

export async function deleteUser(
  setUsers: React.Dispatch<React.SetStateAction<any[]>>,
  userId: string
) {
  try {
    const isConfirmed = window.confirm("Are you sure you want to delete this user?");
    if (isConfirmed) {
      await axiosInstance.post(`/api/admin/delete_user/${userId}`);
      getUserData(setUsers, "");
      successPopup("User deleted successfully");
    }
  } catch (error) {
    errorPopup("Failed to delete user");
  }
}

export async function updateUserPassword(user_id: string, newPassword: string) {
  try {
    await axiosInstance.post(`/api/user/change_password/${user_id}`, {
      newPassword: newPassword,
    });
    successPopup("Password updated successfully.");
  } catch (error) {}
}


export async function updateOffer(id: string, backLink: string, image: File | null) {
  try {
    const formData = new FormData();
    formData.append("backLink", backLink);
    if (image) {
      formData.append("image", image);
    }

    await axiosInstance.post(`/api/offer/update/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data' // Set appropriate content type for FormData
      }
    });

    successPopup("Offer updated successfully.");
  } catch (error) {
    console.error("Error updating offer:", error);
    // Handle error appropriately
  }
}
export async function updateCarouselImage(id: string, isPhone: boolean, backLink: string, image: File | null) {
  try {
    const formData = new FormData();
    if (image) {
      formData.append("image", image);
    }
    if (backLink) {
      formData.append("backLink", backLink);
    }
    if (isPhone) {
      formData.append("isPhone", String(isPhone));
    }

    await axiosInstance.post(`/api/home/update/carouselImg/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data' // Set appropriate content type for FormData
      }
    });

    successPopup("Carousel Image updated successfully.");
  } catch (error) {
    console.error("Error updating offer:", error);
    // Handle error appropriately
  }
}


export async function updateCategory(id: string, title: string, file: File | null) {
  try {
    const formData = new FormData();
    formData.append('title', title);
    if (file) {
      formData.append('icon', file);
    }
    await axiosInstance.post(`/api/category/update/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    successPopup("Category updated successfully.");
  } catch (error) {
    // Handle error
  }
}

export async function updateCarouselImg(id: string, file: File) {
  try {
    await axiosInstance.post(`/api/home/update/carouselImg/${id}`, {
      image: file
    });
    successPopup("Offer updated successfully.");
  } catch (error) {}
}

export async function updateBrand({
  id,
  title,
  headerImg,
  sideText,
  colorCode,
  sideImg,
  couponCode,
  brandLogo,
  description,
  backLink,
  products
}: {
  id: string,
  title: string;
  headerImg: File;
  description: string;
  colorCode: string,
  sideImg: File;
  couponCode: string;
  sideText: string;
  brandLogo: File;
  backLink: string;
  products: Array<object>;
}) {
  // send formdata and string data together
  const productsString = JSON.stringify(products);
  const formData = new FormData();
  formData.append("title", title);
  if(headerImg){
    formData.append("headerImg", headerImg);
  }
  if(sideImg){
    formData.append("sideImg", sideImg);
  }
  if(brandLogo){
    formData.append("brandLogo", brandLogo);
  }
  formData.append("colorCode", colorCode);
  formData.append("description", description);
  formData.append("couponCode", couponCode);
  formData.append("sideText", sideText);
  formData.append("backLink", backLink);
  formData.append("products", productsString);

  try {
    await axiosInstance.post(`/api/brand/update/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    successPopup(`Brand updated successfully`);
  } catch (error) {
    // Handle the error
    errorPopup("Error updating brand.");
  }
}

export async function updateProduct({
  id,
  title,
  category,
  description,
  overlayText,
  couponCode,
  backLink,
  image,
  brand,
}: {
  id: String,
  title: string;
  category: string;
  description: string;
  image: File;
  overlayText: string;
  couponCode: string;
  backLink: string;
  brand: string;
  setData: React.Dispatch<React.SetStateAction<any[]>>;
}) {
  // send formdata and string data together
  const formData = new FormData();
  if(image){
    formData.append("image", image);
  }
  formData.append("title", title);
  formData.append("description", description);
  formData.append("overlayText", overlayText);
  formData.append("category", category);
  formData.append("couponCode", couponCode);
  formData.append("backLink", backLink);
  formData.append("brand", brand);

  try {
    await axiosInstance.post(`/api/product/update/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
    successPopup(`Product updated successfully`);
  } catch (error) {
    // console.log(error);
    errorPopup("Error updating product.");
  }
}
