import React, { useContext, useEffect, useState } from "react";
import OfferCreate from "./tabs/OfferCreate";
import OfferList from "./tabs/OfferList";
import { ToastContainer } from "react-toastify";
import { fetchOffers } from "../../functions/functions.ts";

function Offer({setIsLoading}) {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchOffers(setData);
  }, []);
  return (
    <>
      <div className="content-wrapper">
        <div className="row">
          <div className="col-md-12">
            <div className="w-80 m-auto">
              <ToastContainer />
              <div className="row my-3">
                <div className="">
                  <div className="card">
                    <div className="card-header p-2">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            href="#offers"
                            data-toggle="tab"
                          >
                            Current Offers
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="#offer_create"
                            data-toggle="tab"
                          >
                            Create
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="card-body">
                      <div className="tab-content">
                        <div className="tab-pane active" id="offers">
                          <OfferList data={data} setData={setData} setIsLoading={setIsLoading}/>
                        </div>

                        <div className="tab-pane " id="offer_create">
                          <OfferCreate setData={setData} setIsLoading={setIsLoading}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Offer;
