import React, { useState, useCallback, useMemo } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { createCarouselImage } from "../../../functions/functions.ts";

function AddCarouselImage({ setData, setIsLoading }) {
  const [file, setFile] = useState(null);
  const [backLink, setBackLink] = useState("");
  const [fileName, setFileName] = useState("");
  const [isPhone, setIsPhone] = useState(false);

  const onDropMainFile = useCallback((acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
  }, []);

  const acceptMainFileExtensions = useMemo(() => {
    const accept = {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    };
    return accept;
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropMainFile,
    maxFiles: 1,
    accept: acceptMainFileExtensions,
  });

  const handleCheckboxChange = (e) => {
    setIsPhone(e.target.checked);
  };

  return (
    <>
      <div className="row mx-auto">
        <div className="col-md-12">
          <div className="form-group">
            <label>Carousel Image Back Link</label>
            <input
              type="text"
              name="name"
              id="name"
              onChange={(e) => {
                setBackLink(e.target.value);
              }}
              className={`form-control`}
              placeholder="Enter Carousel Image Backlink"
              style={{
                background: "none",
                border: "1px solid rgba(131, 131,131,0.2)",
                color: "white",
              }}
            />
          </div>
          <div className="form-check mb-3">
            <input
              type="checkbox"
              onChange={handleCheckboxChange}
              className={`form-check-input`}
              // style={{
              //   background: "none",
              //   border: "1px solid rgba(131, 131,131,0.2)",
              //   color: "white",
              // }}
            />
            <label className="form-check-label">- Display on phone</label>
          </div>
          <div>IDEAL IMAGE SIZE - 1872x541</div>
          <Dropzone>
            {() => (
              <div
                {...getRootProps()}
                style={{
                  border: "1px solid rgba(131,131,131,0.2)",
                  borderRadius: "5px",
                  height: "100px",
                  cursor: "pointer",
                  margin: "10px 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <input {...getInputProps()} />

                {fileName ? (
                  <p>{fileName}</p>
                ) : (
                  <p>
                    <i className="fa fa-picture-o" aria-hidden="true"></i>
                    <br />
                    Upload an (File Format .png, .jpg) file
                  </p>
                )}
              </div>
            )}
          </Dropzone>
          <div className="form-group">
            <button
              className="btn btn-primary float-right"
              onClick={async () => {
                setIsLoading(true);
                try {
                  await createCarouselImage(file, backLink, isPhone, setData);
                  document.getElementById("name").value = "";
                  setFileName("");
                } catch (error) {
                  // Handle error
                  console.error("Error creating carousel image:", error);
                  // You might want to show an error message to the user
                }
                setIsLoading(false);
              }}
            >
              Save Image
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCarouselImage;
