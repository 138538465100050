import React, { useEffect, useState } from "react";
import { getUserData, deleteUser, updateUserPassword} from "../../functions/functions.ts";
import { TailSpin } from "react-loader-spinner";

const UserTable = ({ data, setData, setIsLoading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [editUserId, setEditUserId] = useState(null);
  const [editUsername, setEditUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getUserData(setData);
  }, []);

  useEffect(() => {
    if (data.length === 0) {
      setLoading(true);
      setCurrentPage(1); // Set current page to 1 when there's no data
    } else {
      setLoading(false);
    }
  }, [data]);

  const itemsPerPage = 20;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(data.length / itemsPerPage);
  let visibleData = data.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    setCurrentPage((prev) => prev - 1);
    window.scrollTo(0, 0);
  };

  const convertTimeZone = (date) => {
    const dateTimeString = date;
    const dateTime = new Date(dateTimeString);

    const options = {
      day: "2-digit", // Two-digit day
      month: "2-digit", // Two-digit month
      year: "numeric",
      hour: "2-digit", // Two-digit hour
      minute: "2-digit", // Two-digit minute
      second: "2-digit", // Two-digit second
      timeZone: "Asia/Kolkata",
    };

    const formattedDateTime = dateTime.toLocaleString("en-IN", options);
    return formattedDateTime;
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
    window.scrollTo(0, 0);
  };

  const handleDelete = (userId) => {
    deleteUser(setData, userId);
  };

  const handleEdit = (userId, username) => {
    setEditUserId(userId);
    setEditUsername(username);
    setShowModal(true);
  };

  const handleSave = () => {
    setShowModal(false);
    setIsLoading(true);
  
    if (editUserId && newPassword) {
      updateUserPassword(editUserId, newPassword)
        .then(() => {
          // User password updated successfully, hide loader and close modal
          setIsLoading(false);
          setEditUserId(null);
          setNewPassword("");
        })
        .catch(error => {
          // Handle error
          console.error("Error updating user password:", error);
          // You might want to show an error message to the user
          setIsLoading(false);
        });
    } else {
      // Handle case where editUserId or newPassword is missing
      setIsLoading(false);
    }
  };
  

  const handleCloseModal = () => {
    setEditUserId(null);
    setEditUsername("");
    setNewPassword("");
    setShowModal(false);
  };

  const columns = [
    {
      name: "Username",
    },
    {
      name: "Email",
    },
    {
      name: "Last Login",
    },
    {
      name: "Actions",
    },
  ];

  return (
    <>
      {loading ? (
        <TailSpin
          height="80"
          width="80"
          color="#007bff"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass="d-flex flex-column align-items-center justify-content-center"
          visible={loading}
        />
      ) : (
        <>
          <div>
            <div className="d-flex justify-content-between mb-2">
              <h2>User List</h2>
            </div>
            <table className="table">
              <thead className="table-dark">
                <tr>
                  {columns.map((item, ind) => (
                    <th key={ind} scope="col">
                      <div
                        style={{
                          width: "max-content",
                          textAlign: "center",
                          margin: "auto",
                        }}
                      >
                        {item.name}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {visibleData.map((item, ind) => (
                  <tr className="trow" key={ind}>
                    <td>
                      <div
                        style={{
                          width: "120px",
                          textAlign: "center",
                          margin: "auto",
                          position: "relative",
                        }}
                      >
                        {editUserId === item._id ? (
                          <input
                            type="text"
                            value={editUsername}
                            onChange={(e) => setEditUsername(e.target.value)}
                          />
                        ) : (
                          item.username
                        )}
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          width: "auto",
                          textAlign: "center",
                          margin: "auto",
                          position: "relative",
                        }}
                      >
                        {item.email}
                      </div>
                    </td>
                    <td className="text-center">{convertTimeZone(item.lastLogin)}</td>
                    <td className="d-flex justify-content-center">
                      {editUserId === item._id ? (
                        <>
                          <button className="btn btn-primary mr-2" onClick={handleSave}>
                            Save
                          </button>
                          <button className="btn btn-secondary" onClick={handleCloseModal}>
                            Cancel
                          </button>
                        </>
                      ) : (
                        <button className="btn btn-secondary mr-2" onClick={() => handleEdit(item._id, item.username)}>
                          Edit
                        </button>
                      )}
                      <button className="btn btn-danger" onClick={() => handleDelete(item._id)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="m-2 d-flex justify-content-between">
            <div>
              <button
                className="pagination-btn"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                Prev
              </button>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  className={`pagination-btn${
                    currentPage === i + 1 ? " active" : ""
                  }`}
                  key={i}
                  onClick={() => {
                    setCurrentPage(i + 1);
                  }}
                  disabled={currentPage === i + 1}
                >
                  {i + 1}
                </button>
              ))}
              <button
                className="pagination-btn"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>

          {/* Bootstrap modal for editing password */}
          <div className={`modal ${showModal ? "show" : ""}`} style={{ display: showModal ? "block" : "none" }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" style={{color: "black"}}>Edit Password</h5>
                  <button type="button" className="close" onClick={handleCloseModal}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="newPassword" style={{color: "black"}}>New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="newPassword"
                      placeholder="Enter new password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                    Cancel
                  </button>
                  <button type="button" className="btn btn-primary" onClick={handleSave}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Modal backdrop */}
          <div className={`modal-backdrop ${showModal ? "show" : ""}`} style={{ display: showModal ? "block" : "none" }}></div>
        </>
      )}
    </>
  );
};

export default UserTable;
