import React from "react";
import { Link } from "react-router-dom";

function SideBar() {
  const role = localStorage.getItem("role");

  return (
    <>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <Link to="/" className="brand-link">
          <img
            src="dist/img/AdminLTELogo.png"
            alt="AdminLTE Logo"
            className="brand-image img-circle elevation-3"
          />
          <span className="brand-text font-weight-light">RMN ADMIN</span>
        </Link>

        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  <i className="nav-icon fas fa-tag"></i>
                  <p>Products</p>
                </Link>
              </li>
              {role === "admin" ? (
                <li className="nav-item">
                  <Link to="/users" className="nav-link">
                    <i className="nav-icon fas fa-tag"></i>
                    <p>Users</p>
                  </Link>
                </li>
              ) : null}
              <li className="nav-item">
                <Link to="/brands" className="nav-link">
                  <i className="nav-icon fas fa-tag"></i>
                  <p>Brands</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/offers" className="nav-link">
                  <i className="nav-icon fas fa-tag"></i>
                  <p>Offers</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/categories" className="nav-link">
                  <i className="nav-icon fas fa-tag"></i>
                  <p>Categories</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/carousel-images-config" className="nav-link">
                  <i className="nav-icon fas fa-tag"></i>
                  <p>Carousel Config</p>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
}

export default SideBar;
