import React, { useState, useCallback, useMemo, useEffect } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { createOffer } from "../../../functions/functions.ts";

function OfferCreate({ setData, setIsLoading }) {
  const [file, setFile] = useState(null);
  const [Offer, setOffer] = useState("");
  const [invalidClass, setInvalidClass] = useState("");

  const handleChange = (e) => {
    setOffer(e.target.value);
  };

  const onDropMainFile = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
    // console.log(acceptedFiles[0]);
  }, []);

  const acceptMainFileExtensions = useMemo(() => {
    const accept = {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
    };
    return accept;
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropMainFile,
    maxFiles: 1,
    accept: acceptMainFileExtensions,
  });

  return (
    <>
      <div className="row mx-auto">
        <div className="col-md-12">
          <div className="form-group">
            <label>Offer Back Link</label>
            <input
              type="text"
              name="name"
              id="name"
              onChange={handleChange}
              className={`form-control ${invalidClass}`}
              placeholder="Enter Offer Backlink"
              style={{
                background: "none",
                border: "1px solid rgba(131, 131,131,0.2)",
                color: "white",
              }}
            />
          </div>
          <div>IDEAL IMAGE SIZE - 600x260</div>
          <Dropzone
            onDrop={onDropMainFile}
            accept={Object.keys(acceptMainFileExtensions)}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                style={{
                  border: "1px solid rgba(131,131,131,0.2)",
                  borderRadius: "5px",
                  height: "100px",
                  cursor: "pointer",
                  margin: "10px 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <input {...getInputProps()} />
                {file ? (
                  <p>
                    <i className="fa fa-file" aria-hidden="true"></i>
                    <br />
                    {file.name}
                  </p>
                ) : (
                  <p>
                    <i className="fa fa-picture-o" aria-hidden="true"></i>
                    <br />
                    Upload a file (File Format .png, .jpg)
                  </p>
                )}
              </div>
            )}
          </Dropzone>

          <div className="form-group">
            <button
              className="btn btn-primary float-right"
              onClick={async () => {
                setIsLoading(true);
                try {
                  await createOffer(Offer, file, setData);
                  document.getElementById("name").value = "";
                } catch (error) {
                  // Handle error
                  console.error("Error creating offer:", error);
                  // You might want to show an error message to the user
                }
                setIsLoading(false);
              }}
            >
              Save Offer
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default OfferCreate;
