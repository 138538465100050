import React, { useEffect, useState, useCallback, useMemo } from "react";
import DataTable from "react-data-table-component";
import axiosInstance, {
  base,
  handleProductsDisplayUpdate,
  deleteProduct,
  fetchProducts,
  fetchHomeConfig,
  fetchBrands,
  fetchCategories,
  updateProduct,
} from "../../functions/functions";
import { Modal, Button } from "react-bootstrap";
import Dropzone, { useDropzone } from "react-dropzone";

function ProductList({ data, setData, setIsLoading }) {
  const [totalProducts, setTotalProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [homeConfig, setHomeConfig] = useState({
    carouselImages: [],
    displayOffers: [],
    displayBrands: [],
    displayProducts: [],
    __v: 0,
  });
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescripton] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [backLink, setBacklink] = useState("");
  const [overlayText, setOverlayText] = useState("");
  const [brand, setBrand] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [mainFile, setMainFile] = useState(null);
  const [mainFileName, setMainFileName] = useState("");

  const onDropMainFile = useCallback((acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setMainFile(selectedFile);
    setMainFileName(selectedFile.name);
    // console.log(selectedFile.name);
  }, []);

  useEffect(() => {
    setIsLoading(true);
  
    Promise.all([
      fetchHomeConfig(setHomeConfig),
      fetchBrands(setBrands),
      fetchCategories(setCategories),
      populateBrands(),
      fetchProducts(setTotalProducts, 1, 100000000000)
    ])
    .then(() => {
      // All asynchronous operations completed successfully, hide loader
      setIsLoading(false);
      // Scroll to the top of the page
      window.scrollTo(0, 0);
    })
    .catch(error => {
      // Handle error
      console.error("Error fetching data:", error);
      setIsLoading(false);
      // You might want to show an error message to the user
    });
  }, []);
  

  const populateBrands = async () => {
    const response = await axiosInstance.post(`/api/brand/list`);
    const formattedData = response.data.brand.map((brand) => ({
      title: brand.title,
      _id: brand._id,
    }));
    setBrands(formattedData);
  };

  const acceptMainFileExtensions = useMemo(() => {
    const accept = {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
    };
    return accept;
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropMainFile,
    maxFiles: 1,
    accept: acceptMainFileExtensions,
  });

  useEffect(() => {
    if (totalProducts.length === 0 && data.length !== 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [totalProducts, data]);

  const handleDelete = (id) => {
    deleteProduct(id);
  };

  const columns = [
    {
      name: "Home Display",
      cell: (row) => (
        <input
          type="checkbox"
          checked={homeConfig.displayProducts.includes(row._id)}
          onChange={() => {
            handleProductsDisplayUpdate(row._id, setHomeConfig);
          }}
        />
      ),
    },
    {
      name: "Name",
      selector: "title",
    },
    {
      name: "Description",
      selector: "description",
    },
    {
      name: "Category",
      cell: (row) => <div>{row.category ? row.category : "N.A."}</div>,
    },
    {
      name: "Coupon Code",
      cell: (row) => <div>{row.couponCode ? row.couponCode : "N.A."}</div>,
    },
    {
      name: "Backlink",
      cell: (row) => (
        <div title={row.backLink}>
          {row.backLink
            ? row.backLink.length > 40
              ? row.backLink.slice(0, 40) + "..."
              : row.backLink
            : "N.A."}
        </div>
      ),
    },
    {
      name: "Overlay Text",
      selector: "overlayText",
    },
    {
      name: "Brand",
      selector: "brand",
    },
    {
      name: "Image",
      cell: (row) => (
        <img
          src={`${base}${row.image}`}
          alt="Product"
          style={{ width: "50px" }}
        />
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Button
            variant="primary"
            className="mx-2 fz-11px"
            onClick={() => handleEdit(row)}
          >
            Edit
          </Button>
          <Button
            variant="danger"
            className="fz-11px"
            onClick={() => handleDelete(row._id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];
  const handleEdit = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
    setTitle(product.title);
    setOverlayText(product.overlayText);
    setCouponCode(product.couponCode);
    setBacklink(product.backLink);
    setDescripton(product.description);
    setCategory(product.category);
    setBrand(product.brand);
    // Assuming you have a way to set the initial mainFile and mainFileName state
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSaveChanges = () => {
    setShowModal(false);
    setIsLoading(true);
  
    updateProduct({
      id: selectedProduct._id,
      title,
      category,
      description,
      overlayText,
      couponCode,
      backLink,
      mainFile,
      brand,
    })
    .then(() => {
      // Product updated successfully, hide loader and close modal
    })
    .catch(error => {
      // Handle error
      console.error("Error updating product:", error);
      // You might want to show an error message to the user
    })
    .finally(() => {
      setIsLoading(false);
    });
  };
  

  return (
    <>
      <div className="d-flex justify-content-between">
        <h4 className="w-50">Total products: {totalProducts.length}</h4>
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="mr-4"
          style={{
            padding: "0.7rem 1rem",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            borderRadius: "4px",
            width: "300px",
            margin: "0.5rem",
          }}
        />
      </div>
      <div style={{ overflowX: "scroll" }} className="m-3">
        <DataTable
          columns={columns}
          data={totalProducts.filter((item) =>
            item.title.toLowerCase().includes(searchTerm.toLowerCase())
          )}
          pagination
          theme="dark"
        />
      </div>
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="text-dark">Title</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </div>
          <div className="form-group">
            <label className="text-dark">Overlay Text</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setOverlayText(e.target.value)}
              value={overlayText}
            />
          </div>
          <div className="form-group">
            <label className="text-dark">Coupon Code</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setCouponCode(e.target.value)}
              value={couponCode}
            />
          </div>
          <div className="form-group">
            <label className="text-dark">Back Link</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setBacklink(e.target.value)}
              value={backLink}
            />
          </div>
          <div className="form-group">
            <label className="text-dark">Description</label>
            <textarea
              className="form-control"
              onChange={(e) => setDescripton(e.target.value)}
              value={description}
              placeholder="Enter description"
              style={{
                padding: "0.4rem 0.3rem",
                border: "1px solid rgba(0, 0, 0, 0.2)",
                backgroundColor: "rgba(0, 0, 0, 0)",
                borderRadius: "4px",
                width: "100%",
                color: "rgb(131,131,131)",
              }}
            ></textarea>
          </div>
          <div className="form-group">
            <label className="text-dark">Category</label>
            <select
              className="form-control"
              id="categorySelect"
              defaultValue={""}
              value={selectedProduct?.category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value={""}>Select category</option>
              {categories.map((category) => (
                <option key={category._id} value={category.title}>
                  {category.title}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label className="text-dark">Brand</label>
            <select
              className="form-control"
              id="brandSelect"
              defaultValue={selectedProduct?.brand}
              onChange={(e) => setBrand(e.target.value)}
            >
              <option value={""}>Select Brand</option>
              {brands.map((brand) => (
                <option key={brand._id} value={brand.title}>
                  {brand.title}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="text-dark">
              PRODUCT IMAGE --- IDEAL IMAGE SIZE - 354x200
            </label>
            {/* <div>PRODCUT IMAGE --- IDEAL IMAGE SIZE - 354x200</div> */}
            <Dropzone
              onDrop={onDropMainFile}
              maxFiles={1}
              accept={acceptMainFileExtensions}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    height: "100px",
                    cursor: "pointer",
                    margin: "10px 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <input {...getInputProps()} />
                  {mainFileName ? (
                    <p>{mainFileName}</p>
                  ) : (
                    <p>
                      <i className="fa fa-picture-o" aria-hidden="true"></i>
                      <br />
                      Upload an (File Format .png, .jpg etc...) file
                    </p>
                  )}
                </div>
              )}
            </Dropzone>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProductList;
