import React, { useEffect, useState, useCallback, useMemo } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import DataTable from "react-data-table-component";
import axiosInstance, {
  base,
  handleBrandsDisplayUpdate,
  deleteBrand,
  fetchBrands,
  fetchHomeConfig,
  updateBrand,
} from "../../functions/functions";
import { Modal, Button } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";

function BrandList({setIsLoading}) {
  const [totalBrands, setTotalBrands] = useState([]);
  const [editedBrand, setEditedBrand] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [homeConfig, setHomeConfig] = useState({
    carouselImages: [],
    displayOffers: [],
    displayBrands: [],
    displayProducts: [],
    __v: 0,
  });

  const [productsArray, setProductsArray] = useState([]);
  const [title, setTitle] = useState("");
  const [bgcolor, setBgColor] = useState("");
  const [description, setDescripton] = useState("");
  const [sideText, setSideText] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [backLink, setBacklink] = useState("");
  const [products, setProducts] = useState([]);
  const [headerImg, setHeaderImg] = useState(null);
  const [sideBySideImg, setSideBySideImg] = useState(null);
  const [brandLogo, setBrandLogo] = useState(null);
  const [headerImgName, setHeaderImgName] = useState("");
  const [sideBySideImgName, setSideBySideImgName] = useState("");
  const [brandLogoName, setBrandLogoName] = useState("");

  const populateProducts = async () => {
    const response = await axiosInstance.post("/api/product/list");
    const formattedData = response.data.product.map((product) => ({
      label: product.title,
      value: product._id,
    }));
    setProductsArray(formattedData);
  };

  const onDropSideBySideImg = useCallback((acceptedFiles) => {
    setSideBySideImg(acceptedFiles[0]);
    const selectedFile = acceptedFiles[0];
    setSideBySideImgName(selectedFile.name);
    // console.log("Side Img File Name:", selectedFile.name);
  }, []);

  const onDropBrandLogo = useCallback((acceptedFiles) => {
    setBrandLogo(acceptedFiles[0]);
    const selectedFile = acceptedFiles[0];
    setBrandLogoName(selectedFile.name);
    // console.log("Brand Logo File Name:", selectedFile.name);
  }, []);

  const onDropHeaderImg = useCallback((acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setHeaderImg(selectedFile);
    setHeaderImgName(selectedFile.name);
    // console.log("Header Image File Name:", selectedFile.name);
  }, []);
  const acceptHeaderImgExtensions = useMemo(() => {
    const accept = {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    };
    return accept;
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropHeaderImg,
    maxFiles: 1,
    accept: acceptHeaderImgExtensions,
  });

  useEffect(() => {
    setIsLoading(true);
  
    // Asynchronously populate products
    populateProducts(setProductsArray)
      .then(() => {
        // After products are populated, fetch brands
        fetchBrands(setTotalBrands)
          .then(() => {
            // After brands are fetched, fetch home config
            fetchHomeConfig(setHomeConfig)
              .then(() => {
                // All processes completed, hide loader
                setIsLoading(false);
              })
              .catch(error => {
                // Handle error in fetching home config
                console.error("Error fetching home config:", error);
                setIsLoading(false);
              });
          })
          .catch(error => {
            // Handle error in fetching brands
            console.error("Error fetching brands:", error);
            setIsLoading(false);
          });
      })
      .catch(error => {
        // Handle error in populating products
        console.error("Error populating products:", error);
        setIsLoading(false);
      });
  }, []);
  
  const handleEditBrand = (brand) => {
    setEditedBrand(brand);
    setShowModal(true);
    setTitle(brand.title);
    setBgColor(brand.colorCode);
    setCouponCode(brand.couponCode);
    setBacklink(brand.backLink);
    setSideText(brand.sideText);
    setDescripton(brand.description);
    setProducts(brand.products);
    // Assuming you have a way to set the initial mainFile and mainFileName state
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditedBrand(null);
  };

  const handleSaveChanges = () => {
    setShowModal(false);
    setIsLoading(true);
    // Update brand
    updateBrand({
      id: editedBrand._id,
      title,
      headerImg,
      description,
      colorCode: bgcolor,
      sideImg: sideBySideImg,
      couponCode,
      sideText,
      brandLogo,
      backLink,
      products: products,
    })
    .then(() => {
      // Brand updated successfully, hide loader and close modal      
      setEditedBrand(null);
      setIsLoading(false);
    })
    .catch(error => {
      // Handle error
      console.error("Error updating brand:", error);
      setIsLoading(false);
      // You might want to show an error message to the user
    });
  };
  

  const columns = [
    {
      name: "Home Display",
      cell: (row) => (
        <input
          type="checkbox"
          checked={homeConfig.displayBrands.includes(row._id)}
          onChange={() => {
            handleBrandsDisplayUpdate(row._id, setHomeConfig);
          }}
        />
      ),
    },
    {
      name: "Name",
      selector: "title",
    },
    {
      name: "Coupon Code",
      selector: "couponCode",
    },
    {
      name: "Backlink",
      selector: "backLink",
    },
    {
      name: "Brand Logo",
      cell: (row) => (
        <img
          src={`${base}${row.brandLogo}`}
          alt="Brand Logo"
          style={{ width: "50px" }}
        />
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <button
            className="btn mx-2 btn-primary btn-sm"
            onClick={() => handleEditBrand(row)}
          >
            Edit
          </button>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => deleteBrand(row._id, setTotalBrands)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const filteredBrands = totalBrands.filter((brand) =>
    brand.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div>
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="mb-3"
          style={{
            padding: "0.7rem 1rem",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            borderRadius: "4px",
            width: "300px",
            margin: "0.5rem",
          }}
        />
        <DataTable
          theme="dark"
          columns={columns}
          data={filteredBrands}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="60vh"
          highlightOnHover
        />
      </div>

      {/* Brand Edit Modal */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Brand</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="titleInput" className="text-dark">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="titleInput"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="backgroundColorInput" className="text-dark">
              Background Color
            </label>
            <input
              type="text"
              className="form-control"
              id="backgroundColorInput"
              value={bgcolor}
              onChange={(e) => setBgColor(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="couponCodeInput" className="text-dark">
              Coupon Code
            </label>
            <input
              type="text"
              className="form-control"
              id="couponCodeInput"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="backLinkInput" className="text-dark">
              Back Link
            </label>
            <input
              type="text"
              className="form-control"
              id="backLinkInput"
              value={backLink}
              onChange={(e) => setBacklink(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="sideByText" className="text-dark">
              Side By Side Text
            </label>
            <textarea
              className="form-control"
              id="sideByText"
              value={sideText}
              onChange={(e) => setSideText(e.target.value)}
              placeholder="Enter side by side text"
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="descriptionTextarea" className="text-dark">
              Description
            </label>
            <textarea
              className="form-control"
              id="descriptionTextarea"
              value={description}
              onChange={(e) => setDescripton(e.target.value)}
              placeholder="Enter description"
            ></textarea>
          </div>
          <div className="form-group">
            <label>Products</label>
            <MultiSelect
              options={productsArray}
              value={products}
              onChange={setProducts}
              labelledBy="Select"
            />
          </div>
          <div style={{ color: "black" }}>Header Image</div>
          <Dropzone
            onDrop={onDropHeaderImg}
            maxFiles={1}
            accept={acceptHeaderImgExtensions}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  height: "40px",
                  cursor: "pointer",
                  margin: "10px 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <input {...getInputProps()} />
                {headerImgName ? (
                  <p>{headerImgName}</p>
                ) : (
                  <p>
                    <i className="fa fa-picture-o" aria-hidden="true"></i>
                    <br />
                    Upload a file (File Format .png, .jpg)
                  </p>
                )}
              </div>
            )}
          </Dropzone>
          <div style={{ color: "black" }}>Brand Logo</div>
          <Dropzone
            onDrop={onDropBrandLogo}
            maxFiles={1}
            accept={acceptHeaderImgExtensions}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  height: "40px",
                  cursor: "pointer",
                  margin: "10px 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <input {...getInputProps()} />
                {brandLogoName ? (
                  <p>{brandLogoName}</p>
                ) : (
                  <p>
                    <i className="fa fa-picture-o" aria-hidden="true"></i>
                    <br />
                    Upload a file (File Format .png, .jpg)
                  </p>
                )}
              </div>
            )}
          </Dropzone>
          <div style={{ color: "black" }}>Side By Side Img</div>
          <Dropzone
            onDrop={onDropSideBySideImg}
            maxFiles={1}
            accept={acceptHeaderImgExtensions}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  height: "40px",
                  cursor: "pointer",
                  margin: "10px 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <input {...getInputProps()} />
                {sideBySideImgName ? (
                  <p>{sideBySideImgName}</p>
                ) : (
                  <p>
                    <i className="fa fa-picture-o" aria-hidden="true"></i>
                    <br />
                    Upload a file (File Format .png, .jpg)
                  </p>
                )}
              </div>
            )}
          </Dropzone>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BrandList;
