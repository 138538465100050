import React, { useEffect, useState, useMemo, useCallback } from "react";
import DataTable from "react-data-table-component";
import {
  base,
  fetchHomeConfig,
  handleOfferDelete,
  handleOfferDisplayUpdate,
  updateOffer,
} from "../../../functions/functions.ts";
import Dropzone, { useDropzone } from "react-dropzone";

function OfferList({ data, setData, setIsLoading }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [homeConfig, setHomeConfig] = useState({
    carouselImages: [],
    displayOffers: [],
    displayBrands: [],
    displayProducts: [],
    __v: 0,
  });
  const [id, setId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [file, setFile] = useState(null);
  const [backLink, setBackLink] = useState("");
  
  const onDropMainFile = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
    // console.log(acceptedFiles[0])
    // console.log(acceptedFiles[0]);
  }, []);

  const acceptMainFileExtensions = useMemo(() => {
    const accept = {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
    };
    return accept;
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropMainFile,
    maxFiles: 1,
    accept: acceptMainFileExtensions,
  });

  useEffect(() => {
    setIsLoading(true);
  
    // Fetch home config
    fetchHomeConfig(setHomeConfig)
      .then(() => {
        // Home config fetched successfully, hide loader
        setIsLoading(false);
      })
      .catch(error => {
        // Handle error
        console.error("Error fetching home config:", error);
        setIsLoading(false);
        // You might want to show an error message to the user
      });
  }, []);
  

  const columns = [
    {
      name: "Home Display",
      cell: (row) => (
        <div className="ml-5">
          <input
            type="checkbox"
            checked={homeConfig.displayOffers.includes(row._id)}
            onChange={() => {
              handleOfferDisplayUpdate(row._id, setHomeConfig);
            }}
          />
        </div>
      ),
    },
    {
      name: "Back Link",
      selector: (row) => row.backLink.substring(0, 40),
      cell: (row) => (
        <div title={row.backLink}>{row.backLink.substring(0, 40)}</div>
      ),
    },
    {
      name: "Created At",
      selector: (row) => {
        var today = new Date(row.createdAt).toLocaleDateString();
        return today;
      },
    },
    {
      name: "Image",
      cell: (row) => <img src={base + row.image} alt={"Image"} width={100} />,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="btn-group">
          <button
            className="btn btn-primary btn-sm mr-2"
            onClick={() => handleEdit(row)}
          >
            Edit
          </button>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => handleOfferDelete(row._id, setData)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const filteredData = data.filter((row) =>
    row.backLink.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEdit = (row) => {
    setId(row._id);
    setBackLink(row.backLink)
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSaveChanges = async () => {
    setShowModal(false); // Close the modal after successful update
    setIsLoading(true);
  
    try {
      if (file) {        
        // Call your update function with formData
        await updateOffer(id, backLink, file);
      } else {
        // If no file is selected, update offer with only backLink
        await updateOffer(id, backLink);
      }
      
    } catch (error) {
      // Handle error appropriately (e.g., show error message to user)
      console.error("Error updating offer:", error);
      // You might want to show an error message to the user
    }
  
    setIsLoading(false);
  };
  
  
  return (
    <>
      <div>
        <input
          type="text"
          className="mb-3"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            padding: "0.7rem 1rem",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            borderRadius: "4px",
            width: "300px",
            margin: "0.5rem",
          }}
        />
        <DataTable
          theme="dark"
          columns={columns}
          data={filteredData}
          pagination
          fixedHeader
          fixedHeaderScrollHeight="60vh"
          highlightOnHover
        />
      </div>

      {/* Bootstrap Modal for Editing Offer */}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
        id="editOfferModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editOfferModalLabel"
        aria-hidden={!showModal}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="editOfferModalLabel"
                style={{ color: "black" }}
              >
                Edit Offer
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {/* Input fields for editing offer */}
              <div className="form-group">
                <label htmlFor="backLinkInput" style={{ color: "black" }}>
                  Back Link:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="backLinkInput"
                  value={backLink}
                  onChange={(e) =>
                    setBackLink(e.target.value)
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="imageInput" style={{ color: "black" }}>
                  Image:
                </label>
                <Dropzone
                  onDrop={onDropMainFile}
                  accept={Object.keys(acceptMainFileExtensions)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      style={{
                        border: "1px solid rgba(131,131,131,0.2)",
                        borderRadius: "5px",
                        height: "100px",
                        cursor: "pointer",
                        margin: "10px 0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <input {...getInputProps()} />
                      {file ? (
                        <p style={{ color: "black" }}>
                          <i className="fa fa-file" aria-hidden="true"></i>
                          <br />
                          {file.name}
                        </p>
                      ) : (
                        <p style={{ color: "black" }}>
                          <i className="fa fa-picture-o" aria-hidden="true"></i>
                          <br />
                          Upload a file (File Format .png, .jpg)
                        </p>
                      )}
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={handleCloseModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSaveChanges}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
      ></div>
    </>
  );
}

export default OfferList;
